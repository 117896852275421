exports.onClientEntry = () => {
  window.___INITIAL_RENDER = true;

  const loader = document.getElementById('___loader');

  loader.addEventListener(
    'transitionend',
    () => {
      loader.style.display = 'none';
      window.removeEventListener('transitionend', loader);
      window.___INITIAL_RENDER = false;
    },
    false
  );

  window.fadeOutSplashScreen = function() {
    loader.style.opacity = '0';
  };
};
